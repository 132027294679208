body {
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: rgba(0, 0, 0, 0.2);
  user-select: none;
}
body.chakra-ui-dark {
  --scrollbarBG: rgba(255, 255, 255, 0.1);
  --thumbBG: rgba(255, 255, 255, 0.2);
}
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  /* border: 3px solid var(--scrollbarBG); */
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
}

th {
  font-weight: 600;
}

tbody th {
  text-align: right;
}

thead th {
  text-align: left;
}

.App.light th {
  background-color: #f7f7f7;
  /* color: black; */
}

.App.dark th {
  background-color: #222222;
  /* color: white; */
}

th,
td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #eee;

  transition: background-color 100ms, color 100ms, opacity 100ms;
}

.App.light th {
  background-color: #f7f7f7;
  /* color: black; */
}

.App.dark th {
  background-color: #222222;
  /* color: white; */
}

.App.dark th,
.App.dark td {
  border: 1px solid #333;
  /* color: white; */
}

table.interactive-rows tbody tr:hover,
table.interactive-cells tbody td:hover,
tr.interactive:hover,
td.interactive:hover {
  cursor: pointer;
  background-color: #f7f7f7;
  color: black;
}

table.interactive-headers tr th:hover,
th.interactive:hover {
  cursor: pointer;
  background-color: #eee;
  color: black;
}

table.interactive-rows tbody tr td.interactive:hover,
table tbody tr.interactive td.interactive:hover {
  background-color: #f3f3ff;
  color: black;
}

table.sticky-headers th {
  position: sticky;
  top: 0;
  border-top: none;
}

.color-globe,
.color-bar {
  display: inline-block;
  vertical-align: center;
  background: linear-gradient(to right, #f0f 0%, #ff0 33%, #0ff 66%, #f0f 100%);
  background-size: 200% auto;
  height: 1em;
  width: 1em;
  border-radius: 9999px;

  animation: color-cycle 1s linear infinite;
}

.color-bar {
  height: 4px;
  width: auto;
}

@keyframes color-cycle {
  to {
    background-position: -200% center;
  }
}

.fade-in-slow {
  animation: fade-in-opacity 500ms;
}

.fade-in {
  animation: fade-in-opacity 250ms;
}

.slide-in {
  animation: slide-down 200ms ease-out;
}

.slide-from-right {
  animation: slide-from-right 200ms ease-out;
}

.slide-from-left {
  animation: slide-from-left 200ms ease-out;
}

@keyframes fade-in-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-0.75rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-from-right {
  from {
    opacity: 0;
    transform: translateX(1.75rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-from-left {
  from {
    opacity: 0;
    transform: translateX(-1.75rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
